<template>
  <div id="comment-thread" class="comment-thread">
    <h2>Join the conversation</h2>
    <div class="comment-thread__prompt comment-thread__prompt--rules">
      Please
      <a href="https://health-union.com/community-rules/"
         target="_blank"
         rel="noopener noreferrer nofollow"
      >
        read our rules</a>
      before commenting.
    </div>
    <button
      v-if="comments.length"
      :class="`
        comment-thread__toggle
        ${!isClosed ? 'comment-thread__toggle--open': ''}
        text-button
      `"
      :aria-expanded="isClosed ? 'false' : 'true'"
      aria-controls="comment-thread-list"
      @click="toggleComments()"
    >
      {{ toggleCommentsButtonText }}
      <icon-component
        name="caret"
        title="caret icon"
        class="comment-thread__icon icon--small"
      />
    </button>
    <ol
      v-show="!isClosed"
      id="comment-thread-list"
      class="comment-thread__list"
    >
      <li
        v-for="comment in comments"
        :key="`comment-${comment.id}`"
        class="comment-thread__comment"
      >
        <comment-component
          :comment="comment"
          :post="post"
        />
      </li>
    </ol>
    <comment-form
      :mentionable="post"
      @submitted="handleNewComment"
    />
  </div>
</template>

<script>
import CommentComponent from '@/components/Comments/CommentComponent.vue';
import CommentForm from '@/components/Comments/CommentForm.vue';
import scrollToRouteHashMixin from '@/mixins/scroll-to-fragment-mixin';
import scrollToMixin from '@/mixins/scroll-to-mixin';

export default {
  name: 'CommentThread',
  components: {
    CommentComponent,
    CommentForm,
  },
  mixins: [
    scrollToMixin,
    scrollToRouteHashMixin,
  ],
  props: {
    comments: {
      type: Array,
      required: true,
    },
    totalComments: {
      type: Number,
      required: true,
    },
    post: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isClosed: true,
    };
  },
  computed: {
    toggleCommentsButtonText() {
      if (this.isClosed) {
        return `View ${this.totalComments} comments`;
      }
      return `Hide ${this.totalComments} comments`;
    },
    routeHasCommentThreadHash() {
      return this.$route.hash.match(/comment-/);
    },
  },
  created() {
    if (this.routeHasCommentThreadHash) {
      this.toggleComments(true);
      this.$nextTick(this.scrollToRouteHash);
    }
  },
  methods: {
    toggleComments(skipSetUgcFlag) {
      this.isClosed = !this.isClosed;
      if (skipSetUgcFlag) { return; }
      this.$gdfp.setUgcFlag(!this.isClosed);
    },
    openComments() {
      if (!this.isClosed) { return; }
      this.toggleComments();
    },
    handleNewComment(commentId) {
      this.openComments();
      this.$nextTick(() => {
        this.scrollTo(`#comment-${commentId}`);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
  @import '@/stylesheets/components/_comment-thread';
</style>

<docs>
A Comment Thread that displays a list of comments in
chronological order starting from oldest to newest

</docs>
