<template>
  <form-component
    novalidate="true"
    @submit.prevent="handleSubmit"
  >
    <div
      class="validation-group"
      :class="{ 'validation-group--invalid': $v.password.$invalid }"
    >
      <validation-error
        ref="password"
        :model="password"
        :validator="$v.password"
      />
      <password-input
        id="password"
        v-model="password"
        name="post_password"
        class="password-input--full-width"
      />
    </div>
    <loading-button
      class="button"
      :loading="tryingToSubmit"
      type="submit"
    >
      <slot>Submit</slot>
    </loading-button>
  </form-component>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import ValidationError from '@/components/Global/ValidationError.vue';
import PasswordInput from '@/components/Inputs/PasswordInput.vue';
import LoadingButton from '@/components/Global/LoadingButton.vue';

export default {
  name: 'PostPasswordForm',
  components: {
    PasswordInput,
    ValidationError,
    LoadingButton,
  },
  props: {
    postId: {
      type: String,
      required: true,
    },
    postPassword: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      password: '',
      tryingToSubmit: false,
    };
  },
  validations: {
    password: {
      required,
    },
  },
  methods: {
    handleSubmit() {
      this.tryingToSubmit = true;
      this.$v.$touch();
      this.$refs.password.checkErrors();
      if (this.$v.$invalid) {
        this.tryingToSubmit = false;
        return;
      }
      if (this.password !== this.postPassword) {
        this.tryingToSubmit = false;
        this.password = '';
        this.$store.dispatch('addToastNotification', {
          toastType: 'error',
          description: 'The password you entered is not correct.',
        });
        return;
      }
      const authedPostIds = (window.localStorage.getItem('authedPostIds') || '').split(',');
      authedPostIds.push(this.postId);
      window.localStorage.setItem('authedPostIds', authedPostIds);
      this.$emit('passwordEntered');
    },
  },
};
</script>
<docs>
A component used for password protected posts.
</docs>
