/*
This module is based on the example provided by google in their
IMA SDK docs : https://developers.google.com/interactive-media-ads/docs/sdks/html5/client-side

It must be enabled on specific posts with the tag `serve-preroll-ads`.

The idea here is to:
1. Wait for the wistia video to be ready
2. Inject an ad container
3. Attach all of the necessary events and objects from the SDK
4. Play the ad when the video is started and then play the video afterwards.

TODOS:
* Correct playing and pausing of the video - The pause button does not
  pause the ad in progress and in fact you can start the video behind the ad by
  clicking play again. We need to prevent that somehow and maybe hide the controls
  while the ad is in progress.
* Consider moving this into a mixin along with some of the stuff in PostComponent.
*/

let adsLoaded = false;
let adsManager;
let adDisplayContainer;
let videoElement;
let adContainer;
let adsLoader;

const encodeCustomParams = (targetingData) => `ugc%3D${targetingData.ugc}%26tag%3D${targetingData.tag?.join('%2C')}`;

const vastParams = (networkId, targetingData) => ({
  iu: `/${networkId}/${targetingData.ad_prefix}`, // ad unit
  sz: '640x360', // master size for video slot
  ciu_szs: '640x360%7C300x250', // sizes
  gdfp_req: 1, // user is on ad manager schema
  output: 'xml_vast2', // vast format
  vpos: 'preroll', // video position
  cust_params: encodeCustomParams(targetingData), // custom params
  unviewed_position_start: 1, // should be 1
  env: 'instream', // insteam or vp
  plcmt: 1, // can be 1 or 2. 1 means in stream
  wta: 0, // ad badging on or off
  vpmute: 0, // mute player on or off
  vpa: 'click', // auto or click to play
  url: window.location.origin + window.location.pathname, // url of the page
  description_url: window.location.origin + window.location.pathname, // url of the page
  hl: 'en', // language
  correlator: new Date().getTime(), // needs to be a unique value each time
  scor: new Date().getTime(), // page view correlator
  // Below are a bunch of properties that were included in the sorta working
  // URL that someone from Google or Operative gave us. Removing them doesn't
  // seem to affect anything but I'm leaving them here for now as documentation.
  // sdkv: 'h.3.656.2', // not documented
  // osd: 2, // not documented
  // frm: 0, // not documented
  // vis: 2, // not documented
  // sdr: 1, // not documented
  // afvsz: '450x50%2C468x60%2C480x70', // non-linear ad slot sizes - we can probably remove this
  // psd: 'W251bGwsbnVsbCxudWxsLDFd', // not documented
  // is_amp: 0, // not documented
  // uach: 'WyJtYWNPUyIsIjEzLjIuMSIsImFybSIsIiIsIjEyNy4wLjY1MzMuNzMiLG51bGwsMCxudWxsLCI2NCIsW1siTm90KUE7QnJhbmQiLCI5OS4wLjAuMCJdLFsiR29vZ2xlIENocm9tZSIsIjEyNy4wLjY1MzMuNzMiXSxbIkNocm9taXVtIiwiMTI3LjAuNjUzMy43MyJdXSwwXQ..', // not documented
  // u_so: 'l', // not documented
  // ctv: 0, // not documented
  // sdki: 445, // not documented
  // ptt: 20, // not documented
  // adk: 2764283186, // not documented
  // sdk_apis: '2%2C7%2C8', // should not be used in IMA SDK
  // omid_p: 'Google1%2Fh.3.656.2', // should not be used in IMA SDK
  // media_url: 'blob%3Ahttps%253a%2F%2Fmigraine.local.healthunion.io%2Fd61eceaa-3461-4392-94b0-96fe612c5287', // not documented
  // sid: '*', // not supported for web
  // nel: 1, // not documented
  // td: 1, // not documented
  // eid: '95322027%2C95322907%2C95326337%2C95331589%2C95332046%2C95338844', // not documented
  // top: window.location.origin + window.location.pathname, // not documented
  // loc: window.location.origin + window.location.pathname, // not documented
  // dt: 1723134114973, // not documented
  // cookie: '*', // not documented
  // gpic: '*', // not documented
  // eo_id_str: '*', // not documented
  // pvsid: 4040410073130342, // not documented
  // ged: 've4_td71929_tt71928_pd71929_la71929000_er1473.205.1627.505_vi211.0.964.1426_vp0_ts0_eb16427', // not documented
});

const resizeAd = () => {
  if (adsManager) {
    const width = videoElement.clientWidth;
    const height = videoElement.clientHeight;
    adsManager.resize(width, height, window.google.ima.ViewMode.NORMAL);
  }
};

const onAdError = (adErrorEvent) => {
  // eslint-disable-next-line no-console
  console.log(adErrorEvent.getError());
  if (adsManager) {
    adsManager.destroy();
  }
};

const onAdStart = () => {
  // for some reason our adcontainer is doing the play and pause on the video content because
  // it was unreachable to the user when clicking on it. changing the z-index covers up the controls and
  // should make sure that click-through ads still work. its not perfect though because i noticed when the user
  // clicks through in the ad, it pauses the ad and theres no way to play it again
  // just hiding the toolbar doesnt account for the user's click on the actual adcontainer (that still
  // plays and pauses the video)
  adContainer.style.zIndex = '1';
};

const onAdComplete = () => {
  adContainer.style.zIndex = '0';
};

const adContainerClick = () => {
  // I feel like this isn't really doing anything unless the adContainer is actually clicked
  // and it wasn't being clicked before I added that z-index stuff.
  if (videoElement.paused) {
    videoElement.play();
  } else {
    videoElement.pause();
  }
};

const onContentPauseRequested = () => {
  videoElement.pause();
};

const onContentResumeRequested = () => {
  videoElement.play();
};

const setAdsManagerEvents = () => {
  adsManager.addEventListener(
    window.google.ima.AdErrorEvent.Type.AD_ERROR,
    onAdError,
  );
  adsManager.addEventListener(
    window.google.ima.AdEvent.Type.CONTENT_PAUSE_REQUESTED,
    onContentPauseRequested,
  );
  adsManager.addEventListener(
    window.google.ima.AdEvent.Type.CONTENT_RESUME_REQUESTED,
    onContentResumeRequested,
  );
};

const onAdsManagerLoaded = (adsManagerLoadedEvent) => {
  // Instantiate the AdsManager from the adsLoader response and pass it the video element
  adsManager = adsManagerLoadedEvent.getAdsManager(videoElement);
  setAdsManagerEvents();

  adsManager.addEventListener(
    window.google.ima.AdEvent.Type.STARTED,
    onAdStart,
  );
  adsManager.addEventListener(
    window.google.ima.AdEvent.Type.COMPLETE,
    onAdComplete,
  );
};

const setAdsLoaderEvents = () => {
  adsLoader.addEventListener(
    window.google.ima.AdsManagerLoadedEvent.Type.ADS_MANAGER_LOADED,
    onAdsManagerLoaded,
    false,
  );
  adsLoader.addEventListener(
    window.google.ima.AdErrorEvent.Type.AD_ERROR,
    onAdError,
    false,
  );
};

const requestAds = (networkId, targetingData) => {
  const adsRequest = new window.google.ima.AdsRequest();

  // As it is, this URL will display a good XML file and work in the SDK
  //  testing suite: https://googleads.github.io/googleads-ima-html5/vsi/
  const params = new URLSearchParams(vastParams(networkId, targetingData)).toString();
  adsRequest.adTagUrl = `https://pubads.g.doubleclick.net/gampad/ads?${decodeURI(params)}`;

  // Specify the linear and nonlinear slot sizes. This helps the SDK to
  // select the correct creative if multiple are returned.
  adsRequest.linearAdSlotWidth = videoElement.clientWidth;
  adsRequest.linearAdSlotHeight = videoElement.clientHeight;
  adsRequest.nonLinearAdSlotWidth = videoElement.clientWidth;
  adsRequest.nonLinearAdSlotHeight = videoElement.clientHeight / 3;
  adsRequest.pageUrl = window.location.origin + window.location.pathname;

  // Pass the request to the adsLoader to request ads
  adsLoader.requestAds(adsRequest);
};

const initializeIMA = (video, networkId, targetingData) => {
  adDisplayContainer = new window.google.ima.AdDisplayContainer(adContainer, video);
  adsLoader = new window.google.ima.AdsLoader(adDisplayContainer);

  setAdsLoaderEvents();

  video.addEventListener('ended', () => {
    adsLoader.contentComplete();
  });

  requestAds(networkId, targetingData);
};

const loadAds = (event) => {
  if (adsLoaded) return;
  adsLoaded = true;

  // Prevent triggering immediate playback when ads are loading
  event.preventDefault();

  // Initialize the container. Must be done via a user action on mobile devices.
  // videoElement.load(); // This was included in google's example code but it's
  // preventing the ad from pausing during the ad so I've commented it out for now.
  adDisplayContainer.initialize();

  const width = videoElement.clientWidth;
  const height = videoElement.clientHeight;
  // eslint-disable-next-line no-underscore-dangle

  try {
    adsManager.init(width, height, window.google.ima.ViewMode.NORMAL);
    adsManager.start();
  } catch (adError) {
    // Play the video without ads, if an error occurs
    // eslint-disable-next-line no-console
    console.log('AdsManager could not be started', adError);
    videoElement.play();
  }
};

const injectAdContainer = () => {
  // This creates a div to house the ad and cover the video.
  adContainer = document.createElement('div');
  adContainer.id = 'ad-container';
  videoElement.after(adContainer);
};

const setup = (videoObject, networkId, targetingData) => {
  videoElement = document.querySelector('[id^=wistia_simple_video_]');
  injectAdContainer();
  adContainer.addEventListener('click', adContainerClick);
  initializeIMA(videoElement, networkId, targetingData);
  videoElement.addEventListener('play', loadAds);
  // trying stuff for autoplay.  this is also not working:
  // videoElement.setAttribute('autoplay', 'true');
  // videoElement.setAttribute('allow', 'autoplay');

  window.addEventListener('resize', resizeAd);
};

const initializeWistiaAds = (networkId, targetingData) => {
  /*
    The javascript player API docs can be found here:
      https://docs.wistia.com/docs/javascript-player-api
    That's where I got this window level event queue. It lets us set an
    onReady event for these videos so we can kick off all this ad setup.
    The id param there can be a specific video or it can be '_all' to just
    hit any wistia video on the page.
  */
  /* eslint-disable no-underscore-dangle */
  window._wq = window._wq || [];
  window._wq.push({
    id: '_all',
    onReady: (videoOb) => setup(videoOb, networkId, targetingData),
    // this isn't going to work with ads
    // options: {
    //   autoPlay: 'true',
    // },
  });
};

const clearWistiaResizeListener = () => {
  window.removeEventListener('resize', resizeAd);
};

export {
  initializeWistiaAds,
  clearWistiaResizeListener,
};
